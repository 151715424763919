export const BASE_API_URL = "https://api.blueskymss.com/"; //BLUESKY API
export const HOME_URL = "https://staffers.us/"; //Home Website

// //SERER
export const BASE_API_URL_INT = "https://api.staffers.us/api/"; //PRODUCTION
export const BASE_API_URL_FILE = "https://api.staffers.us/"; //PRODUCTION
export const WEB_SOCKET = "api.staffers.us"; //PRODUCTION`


// TRAINING ENV

