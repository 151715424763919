/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react"
import Select, { components } from 'react-select';
import { Formik, Form } from "formik";
import Spinner from "@components/Spinner";
import { InputField } from "@elements/index"
import AdminStore from "@store/AdminStore";
import HomeStore from "@store/HomeStore";
import { Helmet } from "react-helmet";
import SelectField from "@elements/select/SelectField";
import { colourStylesState, colourStyles } from '@utils/customStyle/style';
import { industriesList, shiftTypes, contractTypes, specalitiesList, contractLengthList } from '@utils/constant/constant';
import { CFormSwitch } from "@coreui/react";
import HeaderPage from "../../layout/HeaderPage";
import DrawerMenuPage from "../../layout/DrawerMenuPage";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation, useNavigate } from 'react-router-dom';
import { BASE_API_URL_FILE } from "../../../../api/config";
import MultiRangeSlider from "multi-range-slider-react";
import { JobValidationSchema } from '@utils/schemas/allSchema';
import { toast } from "react-toastify";
import DatePicker from "react-multi-date-picker";
import weekends from "react-multi-date-picker/plugins/highlight_weekends"
import moment from "moment/moment";
import { toJS } from "mobx";
import { MultiValue } from "../../commonFunctions";

var citys = require('@assets/city.json');
var states = require('@assets/states.json');
const AddJob = observer(() => {
    const navigate = useNavigate();
    const params = useLocation();
    const [specialties, setSpecialties] = useState([]);
    const [degree, setDegree] = useState([]);
    const fileInputRef = useRef(null);
    useEffect(() => {
        HomeStore.getDashboardData();
        HomeStore.getSpecialtiesListData(params?.state?.item?.speciality)
    }, []);
    useEffect(() => {
        setDegree(HomeStore?.data?.degreeList)
    }, [HomeStore?.data?.degreeList]);

    useEffect(() => {
        setSpecialties(toJS(HomeStore?.data?.dropDown))
    }, [HomeStore?.data?.dropDown]);

    const onRefresh = () => {
        navigate('/admin/jobs');
    }
    const addJob = (values) => {
        if (params?.state?.item?.id) {
            values.specialty = specialties?.find(x => x.Id == values.specialty)
            values.shift_type = JSON.stringify(values.shift_type)
            AdminStore.updateJob(values, onRefresh)
        } else {
            values.specialty = specialties?.find(x => x.Id == values.specialty)
            values.shift_type = JSON.stringify(values.shift_type)
            AdminStore.addJob(values, onRefresh)
        }
    }
    function ClearButton({ setFiled }) {
        return <div>
            <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => {
                    setFiled('tenure', [])
                }}
            >
                Clear
            </button>
        </div>;
    }
    return (
        <React.Fragment>
            {/* <body> */}
            <Helmet>
                <title>Staffers LLC | Add Job</title>
            </Helmet>
            <div className="container-scroller">
                <HeaderPage />
                <div className="container-fluid page-body-wrapper">
                    <DrawerMenuPage />
                    <div className="main-panel" style={{ right: 0, position: "absolute" }}>
                        <div className="content-wrapper p-3 bg-white ">
                            <h3 className="page-title">
                                <span className="page-title-icon linear text-white me-2">
                                    <i className="fas fa-briefcase"></i>
                                </span> {params?.state?.item?.id ? "Update" : "Add"} Job
                            </h3>

                        </div>
                        <div className="p-2">
                            <Formik
                                initialValues={{
                                    'id': params?.state?.item?.id || null,
                                    'city': params?.state?.item?.city || '',//added
                                    'location_data': params?.state?.item?.location_data || '',//added
                                    'state': params?.state?.item?.state || '',//added
                                    'status': params?.state?.item?.status || '0',
                                    'industry': params?.state?.item?.industry || '',//added
                                    'degree': params?.state?.item?.degree || '',//added
                                    'speciality': params?.state?.item?.speciality || '',//added
                                    'shift_type': params?.state?.item?.shift_type ? JSON.parse(params?.state?.item?.shift_type) : [],//added
                                    'contract_type': params?.state?.item?.contract_type || '',//added
                                    'image': '',//added
                                    'gauranteed_hours': params?.state?.item?.gauranteed_hours || '',//added
                                    'length_weeks': params?.state?.item?.length_weeks || '',//added
                                    'description': params?.state?.item?.description || '',//added
                                    'qualifications': params?.state?.item?.qualifications || '',//added
                                    'benefits': params?.state?.item?.benefits || '',//added
                                    'is_exclusive': params?.state?.item?.is_exclusive || 0,
                                    'pay_details_min': params?.state?.item?.pay_details_min || 1,
                                    'pay_details_max': params?.state?.item?.pay_details_max || 50,
                                    'start_date': params?.state?.item?.start_date || '',
                                    'end_date': params?.state?.item?.end_date || '',
                                    'opening': params?.state?.item?.opening || '',
                                    'paycom_url': params?.state?.item?.paycom_url || ''
                                }}
                                enableReinitialize
                                validateOnChange={false}
                                validationSchema={JobValidationSchema}
                                onSubmit={(values, { resetForm }) => {
                                    addJob(values);
                                }}
                            >
                                {({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className="row g-2">
                                            <div className="col-sm-3 mb-2">
                                                <InputField
                                                    className={"did-floating-input"}
                                                    classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                    type="text"
                                                    name="paycom_url"
                                                    label={"Paycom Job URL"}
                                                    value={values.paycom_url}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                 {errors.paycom_url ? (
                                                    <div className="error-message">{errors.paycom_url}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <SelectField
                                                    name="industry"
                                                    label={"Industry"}
                                                    value={values.industry}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    data={industriesList}
                                                    optionLabel={"label"}
                                                    optionValue={"label"}
                                                />
                                                {errors.industry ? (
                                                    <div className="error-message">{errors.industry}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 did-floating-label-content">
                                                <input
                                                    className={"did-floating-input"}
                                                    ref={fileInputRef}
                                                    type="file"
                                                    name="image"
                                                    onChange={(event) => {
                                                        const exfile = event.currentTarget.files[0];
                                                        if (exfile) {
                                                            // Check image dimensions
                                                            const image = new Image();
                                                            image.src = URL.createObjectURL(exfile);
                                                            image.onload = function () {
                                                                const width = this.width;
                                                                const height = this.height;
                                                                // Check if dimensions meet the requirement
                                                                if (width >= 1024 && height >= 300) {
                                                                    // Set the file value in Formik
                                                                    setFieldValue('image', exfile);
                                                                    const reader = new FileReader();
                                                                    reader.onload = function (e) {
                                                                        document.getElementById('image-preview').innerHTML = `<img src="${e.target.result}"  width="100" alt="Preview">`;
                                                                    };
                                                                    reader.readAsDataURL(exfile);
                                                                } else {
                                                                    // Dimensions do not meet the requirement
                                                                    toast.error('Image dimensions must be at least 1024x300 pixels.');
                                                                    // Clear the file input
                                                                    setFieldValue('image', null);  // Clear the file input using ref
                                                                    fileInputRef.current.value = '';   // Clear the file input using ref
                                                                    document.getElementById('image-preview').innerHTML = ''
                                                                }
                                                            };
                                                        } else {
                                                            setFieldValue('image', null);
                                                        }

                                                    }}
                                                    accept=".png"
                                                />
                                                <label className="did-floating-label did-floating-label-top">Picture of city and state (Image dimensions must be at least 1024x300 pixels.)</label>
                                                {errors.image && touched.image ? (
                                                    <div className="error-message">{errors.image}</div>
                                                ) : null}
                                                <div id="image-preview" ></div>
                                                {params?.state?.item?.image_url && <a className="m-3 pt-2" href={BASE_API_URL_FILE + params?.state?.item?.image_url} target="_blank">
                                                    Old Image : <img src={BASE_API_URL_FILE + params?.state?.item?.image_url} alt="Old Image" width="50" />
                                                </a>}
                                            </div>

                                            <div className="col-sm-3 mb-2">
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    name="shift_type"
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    isMulti
                                                    isRequired={true}
                                                    hideSelectedOptions={false}
                                                    options={shiftTypes}
                                                    styles={colourStyles}
                                                    placeholder=""
                                                    label="Shift Type?"
                                                    onChange={(event) => { setFieldValue("shift_type", event?.map(({ label }) => label)); }}
                                                    value={shiftTypes.filter(({ label }) => values.shift_type?.includes(label)) || ''}
                                                    components={{ Control, MultiValue, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                                {errors.shift_type ? (
                                                    <div className="error-message">{errors.shift_type}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-3 mb-3">
                                                <SelectField
                                                    name="contract_type"
                                                    label={"Contract Type"}
                                                    value={values.contract_type}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    data={contractTypes}
                                                    optionLabel={"label"}
                                                    optionValue={"label"}
                                                />
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <div className="did-floating-label-content">
                                                    <select className="did-floating-dropdown"
                                                        name="degree"
                                                        value={values.degree && JSON.parse(values.degree)?.Id}
                                                        required
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                setFieldValue('degree', JSON.stringify(degree?.find(x => x.Id == e.target.value)))
                                                                HomeStore.getSpecialtiesListData(e.target.value)
                                                            } else {
                                                                setSpecialties([])
                                                            }
                                                        }}
                                                    >
                                                        <option
                                                            value=""
                                                        >
                                                        </option>
                                                        {degree && degree?.map((option, index) => (
                                                            <option
                                                                key={index}
                                                                value={option.Id}
                                                            >
                                                                {option.Name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <label className={values.degree == "" ? "did-floating-label did-floating-label-down did-floating-labe-astrisk" : "did-floating-label did-floating-label-top did-floating-labe-astrisk"}>Job Title/Degree</label>
                                                </div>
                                                {errors.degree && touched.degree ? (
                                                    <div className="error-message">{errors.degree}</div>
                                                ) : null}
                                            </div>
                                            {
                                                values.contract_type === 'Travel Contract' && <><div className="col-sm-3 mb-2">
                                                    <SelectField
                                                        name="speciality"
                                                        label={HomeStore.loading ? "Loading..." : specialties?.length === 0 ? "No Specialty Found" : "Specialty"}
                                                        disabled={!values.degree || HomeStore.loading || specialties?.length === 0}
                                                        value={values.speciality && JSON.parse(values.speciality)?.Id}
                                                        onChange={(e) => {
                                                            setFieldValue('speciality', JSON.stringify(specialties?.find(x => x.Id == e.target.value)))
                                                        }}
                                                        onBlur={handleBlur}
                                                        data={specialties}
                                                        optionLabel={"Name"}
                                                        optionValue={"Id"}
                                                        required={true}
                                                    />
                                                </div>
                                                    <div className="col-sm-3 mb-2">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="number"
                                                            name="gauranteed_hours"
                                                            label={"Guaranteed Hours"}
                                                            value={values.gauranteed_hours}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            // required={true}
                                                            min={1}
                                                        />
                                                        {errors.gauranteed_hours ? (
                                                            <div className="error-message">{errors.gauranteed_hours}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-3 mb-2">
                                                        <SelectField
                                                            name="length_weeks"
                                                            label={"Length of Contract"}
                                                            value={values.length_weeks}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            data={contractLengthList}
                                                            optionLabel={"label"}
                                                            optionValue={"value"}
                                                        />
                                                        {errors.length_weeks && touched.length_weeks ? (
                                                            <div className="error-message">{errors.length_weeks}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="col-sm-3 mb-3">
                                                        <DatePicker
                                                            placeholder="Select Start & End Date"
                                                            calendarPosition="bottom"
                                                            fixMainPosition
                                                            required
                                                            format="MM/DD/YYYY"
                                                            inputClass="did-floating-dropdown"
                                                            range
                                                            rangeHover
                                                            value={[
                                                                values.start_date ? moment(values.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
                                                                values.end_date ? moment(values.end_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : null
                                                            ]}
                                                            minDate={new Date()}
                                                            onChange={dateObjects => {
                                                                const [startDate, endDate] = dateObjects;
                                                                setFieldValue('start_date', startDate?.format('YYYY-MM-DD'));
                                                                setFieldValue('end_date', endDate?.format('YYYY-MM-DD'));
                                                            }}
                                                            plugins={[
                                                                weekends(),
                                                                <ClearButton setFiled={setFieldValue} position="bottom" />
                                                            ]}
                                                        />

                                                    </div>
                                                    <div className="col-sm-3 mb-2">
                                                        <InputField
                                                            className={"did-floating-input"}
                                                            classNameLabel={"did-floating-label did-floating-labe-astrisk"}
                                                            type="number"
                                                            name="opening"
                                                            label={"Number of openings"}
                                                            value={values.opening}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required={true}
                                                            min={1}
                                                        />
                                                        {errors.opening && touched.opening ? (
                                                            <div className="error-message">{errors.opening}</div>
                                                        ) : null}
                                                    </div></>}


                                            <div className="col-sm-6 mb-5">
                                                <label className="mb-1">Pay Details ({values.contract_type !== 'Travel Contract' ? "Hourly rate range" : "Weekly gross amount"}): Min <span className="text-success">${values.pay_details_min}</span>: - Max : <span className="text-success">${values.pay_details_max}</span></label>
                                                <MultiRangeSlider
                                                    label={true}
                                                    min={1}
                                                    max={values.contract_type === 'Travel Contract' ? 6000 : 100}
                                                    step={1}
                                                    ruler={false}
                                                    minValue={values.pay_details_min}
                                                    maxValue={values.pay_details_max}
                                                    barInnerColor="#75449b"
                                                    onChange={(e) => {
                                                        setFieldValue('pay_details_min', e?.minValue)
                                                        setFieldValue('pay_details_max', e?.maxValue)

                                                    }}

                                                />
                                            </div>
                                            <div className="col-sm-3 ps-5 mb-2">
                                                <label className="mb-3  me-3">Exclusive?</label>
                                                <CFormSwitch
                                                    size="xl"
                                                    label=""
                                                    id="formSwitchCheckDefaultXL"
                                                    checked={values.is_exclusive === 1 ? true : false}
                                                    onChange={(e) => {
                                                        console.log(e.target.checked);
                                                        setFieldValue('is_exclusive', e.target.checked ? 1 : 0)
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            <div className="col-sm-3 mb-2">
                                                <label className="mb-3 me-3">Status</label>
                                                <CFormSwitch
                                                    size="xl"
                                                    label=""
                                                    id="formSwitchCheckDefaultXL"
                                                    checked={values.status === '0' ? true : false}
                                                    onChange={(e) => {
                                                        setFieldValue('status', e.target.checked ? '0' : '1')
                                                    }}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                            <div className="col-sm-6 mb-2">
                                                <Select
                                                    onChange={(e) => { setFieldValue("state", e.abbreviation), setFieldValue("city", '') }}
                                                    placeholder={''}
                                                    label={'State'}
                                                    styles={colourStylesState}
                                                    value={states.filter(({ abbreviation }) => values?.state?.includes(abbreviation)) || ''}
                                                    options={states}
                                                    isRequired
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.abbreviation}
                                                    components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                                {errors.state && touched.state ? (
                                                    <div className="error-message">{errors.state}</div>
                                                ) : null}
                                            </div>
                                            <div className="col-sm-6 mb-2">
                                                <Select
                                                    onChange={(e) => {
                                                        setFieldValue("city", e.label);
                                                        setFieldValue("location_data", JSON.stringify([e]))
                                                    }}
                                                    placeholder={''}
                                                    label={'City'}
                                                    isRequired
                                                    isDisabled={!values?.state}
                                                    styles={colourStylesState}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.label}
                                                    value={citys.filter(({ label }) => values?.city === label) || ''}
                                                    options={citys?.filter(ct => ct.state === states?.find(x => x.abbreviation == values?.state)?.name)}
                                                    components={{ Control, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                                />
                                                {errors.city && touched.city ? (
                                                    <div className="error-message">{errors.city}</div>
                                                ) : null}
                                            </div>
                                            {values.contract_type === 'Travel Contract' && <div className="col-sm-12 mb-3">
                                                <label className="pb-2 label">Qualifications</label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    placeholder="Type something"
                                                    data={values?.qualifications}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue("qualifications", data)
                                                    }}
                                                />
                                            </div>}
                                            <div className="col-sm-6 mb-3">
                                                <label className="pb-2 label">Benefits</label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    placeholder="Type something"
                                                    data={values?.benefits}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue("benefits", data)
                                                    }}

                                                />
                                            </div>
                                            <div className="col-sm-6 mb-3">
                                                <label className="pb-2 label">Description</label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    placeholder="Type something"
                                                    data={values?.description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFieldValue("description", data)
                                                    }}

                                                />
                                            </div>

                                        </div>
                                        <div className="pt-4 d-flex justify-content-center pb-3">
                                            <button className="btn btn-primary w-25 p-2" type="submit">Submit</button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>
                {/* <!-- page-body-wrapper ends --> */}

            </div>


            <Spinner isLoading={AdminStore.loading} />

        </React.Fragment >
    );
})
export const Control = (props) => {
    return (
        <>
            <label className={props.hasValue || props.isFocused ? props?.selectProps?.isRequired ? "did-floating-label did-floating-label-top did-floating-labe-astrisk" : "did-floating-label did-floating-label-top" : props?.selectProps?.isRequired ? "did-floating-label did-floating-label did-floating-labe-astrisk" : "did-floating-label did-floating-label"} style={{ zIndex: 10 }}>{props?.selectProps?.label}</label>
            <components.Control {...props} />
        </>
    );
};


export default AddJob;
